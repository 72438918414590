@import "../../style/";
.MenuButton {
  @include reset-link();
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  border-radius: 10px;

  &.MenuButton--important {
    background-color: $color-red;
  }
}
