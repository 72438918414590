.Page {
  .Page__inner {
    margin: auto;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
    box-sizing: border-box;
  }
}
